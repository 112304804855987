<template>
  <main class="tutorial">
    <mobile-fixed-header class="tutorial__mobile-header" @page-close="goToUserGuide">
      <div slot="header" class="ellipsized">
        <span class="title__text">
          {{ $t('dashboard.tutorial.tutorialHowToCreate') }}
        </span>
      </div>
    </mobile-fixed-header>
    <div ref="slide" class="tutorial__content">
      <div class="slide__content flex-column--align-center">
        <div class="content__section content__text">
          <tutorial-text :text-config="slideFirstTextConfig" />
        </div>
        <img :src="slideImageUrl" alt="slide" class="content__section content__image" />
        <div v-if="hasSecondText" class="content__section content__text">
          <tutorial-text :text-config="slideSecondTextConfig" />
        </div>
        <div v-if="hasNote" class="content__note flex ie-text-modal" :class="{ mb30: hasTip }">
          <div class="addition__icon custom-icon icon-exclamation-mark cursor-default"></div>
          <span class="addition__prefix bold mr10">{{ $t('dashboard.tutorial.note') }}</span>
          <span class="addition__text">
            <tutorial-text :text-config="slideNoteTextConfig" />
          </span>
        </div>
        <div v-if="hasTip" class="content__tip flex ie-text-modal">
          <div
            class="tip__check-icon addition__icon custom-icon icon-check-blue cursor-default"
          ></div>
          <span class="addition__prefix bold mr10">{{ $t('dashboard.tutorial.tip') }}</span>
          <span class="addition__text">
            <tutorial-text :text-config="slideTipTextConfig" />
          </span>
        </div>
      </div>
    </div>
    <mobile-fixed-footer class="create-treatment__footer create-tab-footer">
      <template slot="footer">
        <div class="tutorial__footer flex--center--center">
          <div
            v-if="!isFirstSlide"
            class="footer__previous-button flex--center--center"
            @click="onPreviousSlide"
          >
            <div class="custom-icon icon-arrow-long-left"></div>
          </div>
          <div class="footer__carousel flex">
            <div
              v-for="index in slidesLength"
              :key="index"
              class="carousel__point"
              :class="{
                'carousel__point--current': getIsCurrentSlide(index),
                'carousel__point--previous': getIsPreviousSlide(index)
              }"
            ></div>
          </div>
          <div
            class="footer__next-button rounded-button-blue"
            :class="{ 'footer__next-button--finish': isLastSlide }"
            @click="onNextButtonClick"
          >
            {{ nextButtonText }}
          </div>
        </div>
      </template>
    </mobile-fixed-footer>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';
import { get } from 'lodash';

import MobileFixedHeader from '@/modules/dashboard/components/dashboard/common/MobileFixedHeader';
import TutorialText from '@/modules/dashboard/components/tutorial/TutorialText';
import MobileFixedFooter from '@/modules/dashboard/components/dashboard/common/MobileFixedFooter';

import { isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { DEFAULT_PERFECT_SCROLLBAR_OPTIONS } from '@/modules/dashboard/api/constants';
import {
  TUTORIAL_CONFIG_TYPE,
  TUTORIAL_VIEW_TYPE,
  DOCTOR_ACCESS_TYPE,
  TUTORIAL_CONFIG
} from '@/modules/dashboard/api/tutorial';

export default {
  name: 'Tutorial',
  components: { MobileFixedHeader, TutorialText, MobileFixedFooter },
  data() {
    return {
      slides: [],
      currentSlideIndex: 0,
      slideScrollbar: null,
      tutorialViewType: null
    };
  },
  computed: {
    ...mapGetters({
      locale: rootTypes.getters.GET_LOCALE,
      hasWebshopAccess: rootTypes.getters.HAS_WEBSHOP_ACCESS
    }),
    doctorAccessType() {
      return this.hasWebshopAccess ? DOCTOR_ACCESS_TYPE.ONLINE : DOCTOR_ACCESS_TYPE.OFFLINE;
    },
    slidesLength() {
      return this.slides.length;
    },
    currentSlide() {
      return this.slides[this.currentSlideIndex] || null;
    },
    slideFirstTextConfig() {
      return get(this.currentSlide, 'firstText', {});
    },
    slideImageUrl() {
      if (!this.currentSlide) {
        return '';
      }

      const urlPrefix = `${this.locale}/${this.doctorAccessType}/${this.tutorialViewType}`;

      return require(`@/assets/images/dashboard/tutorial/create-treatment/${urlPrefix}/slide${this
        .currentSlideIndex + 1}.png`);
    },
    hasSecondText() {
      return !!get(this.currentSlide, 'secondText');
    },
    slideSecondTextConfig() {
      return get(this.currentSlide, 'secondText', {});
    },
    hasNote() {
      return !!get(this.currentSlide, 'noteText');
    },
    slideNoteTextConfig() {
      return get(this.currentSlide, 'noteText', {});
    },
    hasTip() {
      return !!get(this.currentSlide, 'tipText');
    },
    slideTipTextConfig() {
      return get(this.currentSlide, 'tipText', {});
    },
    isFirstSlide() {
      return this.currentSlideIndex === 0;
    },
    isLastSlide() {
      return this.currentSlideIndex === this.slidesLength - 1;
    },
    nextButtonText() {
      return this.$t(
        `dashboard.${this.isLastSlide ? 'action.finish' : 'diagnostic.nextValidation'}`
      );
    }
  },
  mounted() {
    this.tutorialViewType = isMobileDevice()
      ? TUTORIAL_VIEW_TYPE.MOBILE
      : TUTORIAL_VIEW_TYPE.DESKTOP;

    this.slides = TUTORIAL_CONFIG[TUTORIAL_CONFIG_TYPE.CREATE_TREATMENT][this.doctorAccessType];

    if (isMobileDevice()) {
      return;
    }

    this.slideScrollbar = new PerfectScrollbar(this.$refs.slide, DEFAULT_PERFECT_SCROLLBAR_OPTIONS);
  },
  methods: {
    goToUserGuide() {
      this.$router.push({ name: 'UserGuideMainPage' });
    },
    getIsCurrentSlide(index) {
      return index - 1 === this.currentSlideIndex;
    },
    getIsPreviousSlide(index) {
      return index - 1 < this.currentSlideIndex;
    },
    scrollSlideContentToTop() {
      if (isMobileDevice()) {
        window.scrollTo(0, 0);

        return;
      }

      this.$refs.slide.scrollTop = 0;
    },
    onPreviousSlide() {
      this.currentSlideIndex -= 1;
      this.scrollSlideContentToTop();
    },
    onNextButtonClick() {
      if (!this.isLastSlide) {
        this.currentSlideIndex += 1;
        this.scrollSlideContentToTop();

        return;
      }

      if (isMobileDevice()) {
        this.goToUserGuide();

        return;
      }

      this.$modal.hide('tutorial-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.tutorial {
  &__mobile-header {
    display: none;
  }

  &__content {
    height: 585px;
    position: relative;
    padding: 50px 0 20px;
  }

  &__footer {
    height: 65px;
    padding: 15px 20px;
    position: relative;
    border-top: 1px solid $dim-white;
    background-color: $white;
  }
}

.slide {
  &__content {
    max-width: 510px;
    margin: 0 auto;
  }
}

.content {
  &__text {
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: $text-color;
  }

  &__section {
    max-width: 100%;
    margin-bottom: 30px;
  }

  &__note,
  &__tip {
    border-radius: 10px;
    font-size: 13px;
    color: $text-color;
  }

  &__note {
    padding: 10px 15px;
    background: $paris-daisy;
  }

  &__tip {
    padding: 10px 10px;

    border: 2px solid $sky;
  }
}

.addition {
  &__icon {
    margin-right: 10px;
  }
}

.footer {
  &__previous-button {
    position: absolute;
    top: 22px;
    left: 20px;
  }

  &__next-button {
    min-width: 97px;
    position: absolute;
    top: 15px;
    right: 20px;
    color: $orange-color;
    font-size: 15px;
    font-weight: 600;

    &--finish {
      color: $text-color;
    }
  }
}

.carousel {
  &__point {
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: $azure;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &--current {
      background-color: $orange-color;
    }

    &--previous {
      background-color: $dim-white;
    }
  }
}

@media (max-width: 767px) {
  .tutorial {
    &__mobile-header {
      display: flex;
    }

    &__content {
      height: auto;
      min-height: 100vh;
      padding: 70px 20px 155px;
      background-color: $white;
    }
  }

  .title {
    &__text {
      font-size: 13px;
      color: $text-color;
    }
  }

  .content {
    &__text {
      font-size: 12px;
    }

    &__image {
      max-width: 95%;
    }

    &__note,
    &__tip {
      font-size: 11px;
    }
  }

  .addition {
    &__icon {
      margin: -3px 7px 0 0;
    }
  }

  .footer {
    background-color: $white;

    &__previous-button {
      width: 75px;
      height: 30px;
      top: 18px;
      border: 1px solid $dim-white;
      border-radius: 50px;
      background-color: $white;
    }

    &__next-button {
      min-width: 75px;
      height: 30px;
      top: 18px;
      font-size: 13px;
      padding: 7px 20px;
    }
  }

  .carousel {
    &__point {
      width: 5px;
      height: 5px;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
}
</style>
