<template>
  <section>
    <i18n :path="textKey" tag="span">
      <template v-for="element in customElements">
        <span
          v-if="getIsCustomElementButton(element.type)"
          :key="element.value"
          class="custom-element__button"
        >
          {{ $t(element.value) }}
        </span>
        <div
          v-if="getIsCustomElementIcon(element.type)"
          :key="element.value"
          class="custom-element__icon custom-icon"
          :class="`icon-${element.value}`"
        ></div>
        <span
          v-if="getIsCustomElementRecommended(element.type)"
          :key="element.value"
          class="custom-element__recommended-container"
        >
          <div
            class="custom-element__recommended-icon custom-icon icon-check-green inline-block"
          ></div>
          <i class="custom-element__recommended-text">{{ $t('dashboard.label.recommended') }}</i>
        </span>
        <span
          v-if="getIsCustomElementGreyTextButton(element.type)"
          :key="element.value"
          class="custom-element__grey-text-button"
        >
          {{ $t(element.value) }}
        </span>
        <a
          v-if="getIsCustomElementLink(element.type)"
          :key="element.value"
          class="custom-element__link"
          :href="element.link"
          target="_blank"
          >{{ $t(element.value) }}</a
        >
      </template>
    </i18n>
  </section>
</template>

<script>
import { get } from 'lodash';

import { TUTORIAL_CUSTOM_ELEMENTS_TYPES } from '@/modules/dashboard/api/tutorial';

export default {
  name: 'TutorialText',
  props: {
    textConfig: {
      type: Object,
      required: true
    }
  },
  computed: {
    textKey() {
      return get(this.textConfig, 'textKey', '');
    },
    customElements() {
      return get(this.textConfig, 'customElements', []);
    },
    customElementType() {
      return get(this.customElements, 'type', '');
    },
    customElementValue() {
      return get(this.customElements, 'value', '');
    },
    isCustomElementRecommended() {
      return this.customElementType === TUTORIAL_CUSTOM_ELEMENTS_TYPES.RECOMMENDED;
    }
  },
  methods: {
    getIsCustomElementButton(type) {
      return type === TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON;
    },
    getIsCustomElementIcon(type) {
      return type === TUTORIAL_CUSTOM_ELEMENTS_TYPES.ICON;
    },
    getIsCustomElementRecommended(type) {
      return type === TUTORIAL_CUSTOM_ELEMENTS_TYPES.RECOMMENDED;
    },
    getIsCustomElementGreyTextButton(type) {
      return type === TUTORIAL_CUSTOM_ELEMENTS_TYPES.GREY_TEXT_BUTTON;
    },
    getIsCustomElementLink(type) {
      return type === TUTORIAL_CUSTOM_ELEMENTS_TYPES.LINK;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/icons';

.custom-element {
  &__button {
    margin-right: 3px;
    padding: 2px 2px 2px 5px;
    border: 2px solid $whisper;
    border-radius: 10px;
    color: $orange-color;
    font-weight: 600;
    white-space: nowrap;
  }

  &__icon {
    display: inline-block;
    margin-bottom: -5px;
  }

  &__recommended-container {
    white-space: nowrap;
  }

  &__recommended-icon {
    margin-bottom: 1px;
  }

  &__recommended-text {
    color: $green-color;
  }

  &__grey-text-button {
    color: $grey-color;
  }

  &__link {
    color: $links-color;
    text-decoration: underline;
  }
}
</style>
