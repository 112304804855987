<template>
  <header class="mobile-header flex--space-between--center">
    <slot name="header"></slot>
    <div class="icon-close custom-icon ml10" @click="onCloseButtonClick"></div>
  </header>
</template>

<script>
export default {
  name: 'MobileFixedHeader',
  methods: {
    onCloseButtonClick() {
      this.$emit('page-close');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/scss/helpers/colors';

@media (max-width: 767px) {
  .mobile-header {
    height: 50px;
    padding: 0 20px;
    position: fixed;
    z-index: 9;
    top: 0;
    border-bottom: 1px solid $dim-white;
    font-size: 13px;
    font-weight: 600;
    color: $text-color;
    background-color: $white;
  }
}
</style>
